.product-image {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: -1000;
  object-fit: cover;
  overflow: hidden;
  /* background-position-y: 2rem; */
  object-position: 0rem 20%;
  /* object-position: 50% 50%; */
}
/* .images-shoot {
  position: absolute;
  top: 40%;
  left: 28.5%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
} */
.images-shoot-lifestyle {
  position: absolute;
  top: 40%;
  left: 28.5%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
}
.lifestyle-div {
  width: 100%;
}
.product-image {
  width: 100%;
}
@media (max-width: 415px) {
  .images-shoot-lifestyle {
    font-size: 2.4rem;
    left: 10%;
  }
  .product-image {
    height: 70vh;
  }
}
.video-shoot-container-lifestyle {
  /* display: flex; */
  flex-wrap: wrap;
  column-count: 2;
  gap: 30px;
  margin: auto;
  width: 90%;
}
.button-get-in-cat {
  transition: all 0.5s ease-in-out;
}
.button-get-in-cat:hover {
  /* position: absolute; */
  background-color: #333;
  color: white;
}

@media (max-width: 768px) {
  .product-image {
    height: 50vh;
  }
  .images-shoot {
    top: 40%;
    left: 28.5%;
    font-size: 2.5rem;
  }
  .video-shoot-container {
    width: 100%;
    column-count: 1;
  }
}

@media (max-width: 400px) {
}
