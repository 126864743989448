.portfolio-page {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  padding: 0 20px;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
  column-width: 25%;
}

.portfolio-page__item img {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin: 7px 0;
}

@media (max-width: 991px) {
  .portfolio-page {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
  }
}

@media (max-width: 768px) {
  .portfolio-page {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
  }
}

@media (max-width: 500px) {
  .portfolio-page {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

.portfolio-page__item img:hover {
  transform: scale(1.01);
  opacity: 0.8;
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4 ease;
}

.model.open {
  background-color: rgba(0, 0, 0, 0.8);
  visibility: visible;
  opacity: 1;
}

.model img {
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  box-sizing: border-box;
  padding: 30px 0;
  margin: 0 auto;
}

img,
svg {
  vertical-align: unset;
}
.fa-xmark {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-weight: bold;
  font-size: 2.3rem;
}

.my-collection {
  font-family: Poppins;
  font-size: 3rem;
  /* font-weight: bold; */
}
.menu-tab {
  width: 100%;
  overflow: scroll;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  /* flex-wrap: nowrap; */
}

.menu-tab-item {
  padding: 10px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  transition: all 350ms ease-in;
  font-size: 1.2rem;
}

.menu-tab-item:hover {
  background-color: rgb(46, 46, 46);
  border: 1px solid black;
  transform: scale(1.1);
  color: white;
}
.portfolio-head-img {
  position: relative;
}
.portfolio-head-img h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 6rem;
  letter-spacing: 8px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .menu-tab {
    padding: 0 20px;
    justify-content: start;
    /* margin-left: -100px; */
    /* flex-direction: column; */
  }
  .menu-tab-item {
    margin: 10px;
    border: 2px solid rgb(87, 87, 87);
  }
  .portfolio-head-img h1 {
    font-size: 4rem;
    letter-spacing: 3px;
  }
}

@media (max-width: 400px) {
  .my-collection {
    font-size: 3rem;
    margin: 30px 0;
  }
  .portfolio-head-img h1 {
    font-size: 3rem;
  }
  .menu-tab-item {
    font-size: 1rem;
  }
  .menu-tab {
    margin-bottom: 30px;
  }
}
.photoshoot-text {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}
@media (max-width: 500px) {
  .portfolio-head-img h1 {
    text-align: center;
    font-size: 2.5rem;
  }
}
.photoshoot-img-12 {
  width: 100px;
}
