.footer-wrapper {
  position: relative;
}

.text-work-together {
  position: absolute;
  top: 50%;
  left: 10%;
}
.cine-stories {
  background-color: black;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  padding: 2px 20px;
}
.footer-wrapper img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.text-work-together h1 {
  font-size: 7rem;
  font-weight: 700;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  line-height: 8.5rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
  font-family: "Open Sans", "Helvetica";
}

.text-journey {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  color: #333;
}

.text-journey h1 {
  font-size: 3rem;
  font-family: Poppins;
  text-align: center;
}

.text-journey p {
  width: 50%;
  text-align: center;
}

.getInTouch {
  position: absolute;
  top: 70%;
  left: 68%;
}

.getInTouch button {
  padding: 1rem 3rem;
  cursor: pointer;
  font-family: Poppins;
  font-weight: bold;
  border: none;
  transition: 400ms ease-in-out;
  font-size: 1.05rem;
}

.getInTouch button:hover {
  background-color: rgb(61, 61, 61);
  color: rgb(255, 255, 255);
}
.work-together {
  display: flex;
  overflow: auto;
}

@media (max-width: 1100px) {
  .text-work-together h1 {
    font-size: 4rem;
    line-height: 5.2rem;
  }

  /* .text-journey {
    top: -5%;
  } */
  /* .text-journey p {
    margin-top: -40px;
  } */
}

@media (max-width: 768px) {
  .text-journey {
    left: 50%;
  }
  .text-work-together {
    top: 40%;
    left: 30%;
  }
  .text-work-together h1 {
    font-size: 3rem;
    line-height: 4rem;
    text-align: center;
  }
  .getInTouch {
    left: 35%;
  }
  .getInTouch button {
    font-size: 0.7rem;
  }
  .text-journey h1 {
    font-size: 3.2rem;
  }
  .text-journey p {
    font-size: 0.7rem;
  }
}

@media (max-width: 600px) {
  .text-work-together {
    top: 40%;
    left: 33%;
  }
  .text-work-together h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .getInTouch {
    top: 65%;
    left: 37%;
  }
  .getInTouch button {
    padding: 0;
    width: 150px;
    height: 50px;
  }
  .text-journey h1 {
    font-size: 3rem;
  }
  .text-journey p {
    font-size: 0.5rem;
  }
}

@media (max-width: 768px) {
  .bg-image-footer img {
    height: 70vh;
  }
  text-journey h1 {
    font-size: 1rem;
  }
  .text-work-together {
    top: 33%;
    left: 34%;
  }
  .text-work-together h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;
  }
  .getInTouch {
    top: 60%;
    left: 39%;
  }
  .getInTouch button {
    font-size: 1rem;
    width: 150px;
    height: 60px;
    padding: 0;
    outline: 2px solid black;
  }
  .text-journey {
    left: 50%;
  }
  .text-journey h1 {
    font-size: 1.5rem;
    letter-spacing: 2px;
    width: 300px;
  }
  .text-journey p {
    display: none;
  }
}
@media (max-width: 500px) {
  .bg-image-footer img {
    height: 70vh;
  }
  text-journey h1 {
    font-size: 1rem;
  }
  .text-work-together {
    top: 33%;
    left: 22%;
  }
  .text-work-together h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;
  }
  .getInTouch {
    top: 60%;
    left: 29%;
  }
  .getInTouch button {
    font-size: 1rem;
    width: 150px;
    height: 60px;
    padding: 0;
    outline: 2px solid black;
  }
  .text-journey {
    left: 50%;
  }
  .text-journey h1 {
    font-size: 1.5rem;
    letter-spacing: 2px;
    width: 300px;
  }
  .text-journey p {
    display: none;
  }
}
