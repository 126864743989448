.category-div {
  position: relative;
}
.shoot-tag-line {
  position: absolute;
  z-index: 1000;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 3px;
}
.bg-image {
  /* position: relative; */
  width: 100%;
  height: 100vh;
  z-index: -100;
  /* object-fit: cover; */
}
.category-div video {
  width: 100%;
  height: 90%;
}
.category-div h1 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 15%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* text-align: center; */
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

@media (max-width: 2500px) {
}
.two-buttons {
  /* display: flex; */
  position: absolute;
  top: 80%;
  /* left: 32%; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  /* transform: translate(-50%, -50%); */
}
.two-buttons button:hover {
  opacity: 1;
}
.two-buttons .shoot-button a {
  text-decoration: none;
  color: ghostwhite;
}
.two-buttons .shoot-button {
  text-align: center;
  height: 40px;
  background-color: rgba(23, 26, 32, 0.95);
  width: 250px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 100px;
  opacity: 0.85;
  font-size: 12px;
  margin: 8px;
  border: none;
}
.two-buttons .button-existing-inventory a {
  text-decoration: none;
  color: #333;
}
.two-buttons .button-existing-inventory {
  text-align: center;
  height: 40px;
  background-color: white;
  width: 250px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 100px;
  opacity: 0.95;
  font-size: 12px;
  margin: 8px;
  border: none;
}

@media (max-width: 500px) {
  .category-div h1 {
    display: none;
  }

  .two-buttons .shoot-button {
    height: 20px;
    font-size: 10px;
    width: 100px;
  }
  .two-buttons .button-existing-inventory {
    height: 20px;
    font-size: 10px;
    width: 100px;
  }
  /* .button-existing-inventory {
    font-size: 3px;
    width: 100px;
  }
  .shoot-button {
    font-size: 3px;
    width: 100px;
    display: none;
  } */
}

.shoot-tag-line {
  padding: 2px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  font-family: "Lato";
}
@media (max-width: 500px) {
  .shoot-tag-line {
    font-size: 12px;
    text-align: center;
  }
}
@media (max-width: 1500px) {
  .category-div h1 {
    text-align: center;
  }
}
