.more-about-img h1 {
  position: absolute;
  top: 50%;
  /* left: 30%; */
  text-align: center;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 4rem;
  letter-spacing: 8px;
  font-weight: bold;
  transform: scale(1.5, 1.8);
  width: 100%;
  text-align: center;
}
.more-about-img {
  position: relative;
}
.about-image img {
  object-position: 0 20%;
}
@media (max-width: 1100px) {
  .more-about-img h1 {
    width: 100%;
    font-size: 3rem;
    text-align: center;
  }
}
.about-section-image img {
  border-radius: 12px;
}

@media (max-width: 840px) {
  .more-about-img h1 {
    font-size: 2.2rem;
    letter-spacing: 3px;
  }
}

@media (max-width: 640px) {
  .more-about-img h1 {
    font-size: 1.8rem;
    letter-spacing: 1px;
  }
}

.about-image img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.about-me-main {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.about-me {
  font-family: Poppins;
  position: absolute;
  padding: 50px;
  width: 400px;
  height: 390px;

  background-color: #f8f3ed;
  position: absolute;
  top: 25%;
  left: 10%;
  transform: translate(-50%, -50%);
}
.about-me p {
  font-size: 0.9rem;
  margin: 20px 0;
}
.about-me button {
  background-color: transparent;
  padding: 5px 20px;
  transition: all 0.3s ease-in;
  border: 1px solid #333;
}
.about-me button:hover {
  background-color: black;
  color: white;
}
@media (max-width: 768px) {
  .about-me {
  }
}
@media (max-width: 500px) {
  .about-me {
    width: 90%;
  }
  .more-about-img h1 {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .about-me-more {
    margin: 0 auto;
    left: 5.4%;
    top: 20%;
  }
}
@media (max-width: 400px) {
  .about-me-more {
    left: 3.4%;
  }
}
