@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* h1,
h2 {
  font: inherit;
} */

/* body {
  font: 16px/1.2 "Poppins", sans-serif;
  background: linear-gradient(
    45deg,
    hsl(236, 100%, 80%, 0.4),
    rgba(205, 155, 225, 0.3)
  );
} */

.gallery {
  --anim-time--hi: 266ms;
  --anim-time--med: 400ms;
  --anim-time--lo: 600ms;

  display: flex;
  place-content: center;
  /* max-width: clamp(30rem, 95%, 50rem); */
  width: max(22.5rem, 100%);
  min-height: 100vh;
  margin-inline: auto;
  padding: clamp(0px, (30rem - 100vw) * 9999, 1rem);
}

.gallery__content--flow {
  display: flex;
  flex-wrap: wrap;
  /* gap: 1rem; */
}

.gallery__content--flow > * {
  flex-grow: 1;
  flex-basis: calc((30rem - 100%) * 999);
}

figure {
  display: flex;
  min-width: 14rem;
  max-height: 16rem;
  position: relative;
  border-radius: 0.35rem;
  box-shadow: rgb(40, 40, 40, 0.1) 0px 2px 3px, rgb(20, 20, 20, 0.2) 0px 5px 8px,
    rgb(0, 0, 0, 0.25) 0px 10px 12px;
  overflow: hidden;
  transition: transform var(--anim-time--med) ease;
}

figure::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to top,
    hsla(0, 0%, 0%, 0.8) 0%,
    hsla(0, 0%, 0%, 0.7) 12%,
    hsla(0, 0%, 0%, 0.2) 41.6%,
    hsla(0, 0%, 0%, 0.125) 50%,
    hsla(0, 0%, 0%, 0.01) 59.9%,
    hsla(0, 0%, 0%, 0) 100%
  );
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: var(--anim-time--med), var(--anim-time--med);
  transition-timing-function: ease, ease;
  z-index: 4;
}

.header__caption {
  z-index: 10;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  /* gap: 0.5rem; */
  /* padding: 1rem; */
  justify-content: center;
  text-align: center;
  transform: translateY(100%);
  transition: transform var(--anim-time--hi) linear,
    opacity var(--anim-time--hi) linear;
}

figure:hover::before {
  opacity: 0.8;
}

figure:hover .header__caption {
  transform: translateY(0);
  opacity: 1;
}
figure:hover img {
  transform: scale(1);
}
.title {
  color: #fff;
}

.title--primary {
  /* font-size: 1.25rem; */
  /* font-weight: bold; */
}

.title--secondary {
  text-transform: uppercase;
  font-weight: bold;
}

figure img {
  display: block;
  width: 100%;
  object-fit: cover;
  /* object-position: center; */
  height: 100%;
  transform: scale(1.15);
  /* aspect-ratio: 16 / 13; */
  transition: 400ms ease-in-out;
}

figure a {
  text-decoration: none;
  list-style-type: none;
}

figcaption a h1 {
  font-family: "Ms Madi", cursive;
  font-weight: light;
  font-size: 2rem;
}

/*  portfolio image css */

.portfolio-begin img {
  width: 100%;
  height: 90vh;
}
.portfolio-begin {
  position: relative;
}
.portfolio-text {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 5rem;
}
