.product-image {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: -100;
  object-fit: cover;
  object-position: 0rem 10%;
  /* object-position: 50% 50%; */
}
.images-shoot {
  position: absolute;
  top: 40%;
  left: 35.5%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
}
.video-shoot-container-influence {
  flex-wrap: wrap;
  column-count: 2;
  gap: 30px;
  margin: auto;
  width: 90%;
}
.influencer-specific {
  left: 33.5%;
}

@media (max-width: 500px) {
  .influencer-specific {
    font-size: 2rem;
    left: 24%;
  }
}
.product-image {
  background-position: 80%;
  background-size: cover;
}
