/* .header-container {
  position: sticky;
  z-index: 100;
} */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul a {
  text-decoration: none;
  color: #333;
  text-transform: uppercase;
  margin: 0 20px;
  transition: all 0.1s;
  font-weight: bold;
  font-family: "Lato";
}

a:hover {
  /* border: 2px solid rgb(114, 113, 113); */
}

.wrapper img {
  width: 120px;
}
.mobile-menu-icon {
  display: none;
}
@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    /* align-items: center; */
  }

  .wrapper img {
    margin-left: -170px;
    width: 80px;
  }
  .header-mobile-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .header-links {
    display: none;
  }
  .wrapper li a {
    padding: 10px 0;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 25px;
    border: none;
    outline: none;
    color: black;
    background-color: white;
    font-size: 30px;
  }
}
