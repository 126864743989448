/* @import url(https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap); */
.main-reel {
  position: relative;
}
.video-title {
  text-decoration-thickness: 4px;
  position: absolute;
  font-size: 5rem;
  text-transform: uppercase;
  color: white;
  top: 37%;
  text-align: center;
  width: 100vw;
  font-family: "Lato";
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.video-container {
  position: relative;
}

.position {
  text-align: center;
  font-family: Poppins;
  font-size: 5rem;
}

@media (max-width: 768px) {
  .video-title {
    font-size: 3rem;
    top: 35%;
  }
  .position {
    font-size: 3rem;
  }
}
@media (max-width: 500px) {
  .video-title {
    top: 30%;
    font-size: 2rem;
  }

  .position {
    margin-top: 40px;
    font-size: 1.75rem;
    font-weight: bold;
  }
}

.position {
  background-color: #f8f3ed;
  padding: 50px 0;
  margin-top: 20px;
  font-size: 2.75rem;
  font-family: Poppins;
  /* margin-bottom: 20px; */
}
