@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  /* background-color: #6a9ac4; */
}

#contact {
  background-color: #cdd4db;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-box {
  width: clamp(100px, 90%, 1000px);
  margin: 80px 50px;
  display: flex;
  flex-wrap: wrap;
}

.contact-links,
.contact-form-wrapper {
  width: 50%;
  padding: 8% 5% 10% 5%;
}

.contact-links {
  background-image: url("https://images.weserv.nl/?url=https://crossfadefilm.com/_CFF0048-2.JPG&w=500&output=webp&maxage=31d");
  background-repeat: no-repeat;

  border-radius: 10px 0 0 10px;
}
@media (max-width: 768px) {
  .contact-links {
    background-image: url("https://images.weserv.nl/?url=https://crossfadefilm.com/DCP_9903.jpg&w=500&output=webp&maxage=31d");
    margin-left: 0px 0px 0px 30px;
  }
  .contact-me-head {
    position: relative;
    top: -120px;
  }
  .contact-me {
    position: relative;
    top: -100px;
  }
  /* .contact-links { 
    background-color: #1f2e43;
    background: radial-gradient(
        circle at 55% 92%,
        #426691 0 12%,
        transparent 12.2%
      ),
      radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%),
      radial-gradient(
        circle at 20% max(78%, 350px),
        #263a53 0 7%,
        transparent 7.2%
      ),
      radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%),
      #1f2e43;
    object-position: 200px 0;
  } */
}
.contact-me {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
}
.contact-form-wrapper {
  background-color: #ffffff8f;
  border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 800px) {
  .contact-links,
  .contact-form-wrapper {
    width: 100%;
  }

  .contact-links {
    border-radius: 10px 10px 0 0;
  }

  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 400px) {
  .contact-box {
    width: 95%;
    margin: 8% 5%;
  }
}

h2 {
  font-family: "Arimo", sans-serif;
  color: #fff;
  font-size: clamp(30px, 6vw, 60px);
  letter-spacing: 2px;
  text-align: center;
  transform: scale(0.95, 1);
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
}

.link {
  margin: 10px;
  cursor: pointer;
}

.contact-img {
  width: 45px;
  height: 45px;
  filter: hue-rotate(220deg) drop-shadow(2px 4px 4px #0006);
  transition: 0.2s;
  user-select: none;
}

.form-item {
  position: relative;
}

label,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

label {
  position: absolute;
  top: 10px;
  left: 2%;
  color: #999;
  font-size: clamp(14px, 1.5vw, 18px);
  pointer-events: none;
  user-select: none;
}

input,
textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

input:focus + label,
input:valid + label,
textarea:focus + label,
textarea:valid + label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all 0.225s ease;
}

.submit-btn-1 {
  background-color: rgb(97, 220, 248);
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: clamp(16px, 1.6vw, 18px);
  display: block;
  padding: 12px 20px;
  margin: 2px auto;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
}

@media only screen and (max-width: 800px) {
  h2 {
    font-size: clamp(40px, 10vw, 60px);
  }
}

@media only screen and (max-width: 400px) {
  h2 {
    font-size: clamp(30px, 12vw, 60px);
  }

  .links {
    padding-top: 30px;
  }

  /* img {
    width: 38px;
    height: 38px;
  } */
}
.contact-me-head {
  margin-top: 150px;
}
