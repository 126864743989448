.product-image {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: -100;
  object-fit: cover;
  /* object-position: 50% 50%; */
}
.images-shoot-events {
  position: absolute;
  top: 40%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
}

@media (max-width: 768px) {
  .images-shoot-events {
    font-size: 2rem;
    left: 30%;
  }
}
@media (max-width: 400px) {
  .images-shoot-events {
    font-size: 2rem;
    left: 18%;
  }
}
.video-shoot-container-1 {
  column-count: 2;
  margin: auto;
  width: 70%;
}

@media (max-width: 1118px) {
  .video-shoot-container-1 {
    column-count: 1;
  }
}
.button-get-in-cat:hover {
  /* background-color: white; */
}
