/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap");
.about-me-section {
  display: flex;
  font-family: "Open Sans", sans-serif;
}

.about-me-container {
  margin: 0 auto;
  flex: 1;
  padding: 0 70px;
}
.about-section-image {
  flex: 1;
  overflow: hidden;
  margin: 0px;
  height: 500px;
  margin: 0 auto;
}
.about-me-text {
  font-size: 3rem;
}

.about-section-image img {
  width: 100%;
}
.more-about {
  margin: 0px;
  padding: 15px 20px;
  background-color: #333;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 250ms;
}

.more-about:hover {
  background-color: rgb(151, 149, 149);
  color: black;
}

@media (max-width: 768px) {
  .about-me-section {
    flex-direction: column;
  }
  .about-section-image img {
    height: 400px;
  }
}

@media (max-width: 640px) {
  .about-me-text {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .about-me-text {
    font-size: 2rem;
  }
} */
.about-image {
  width: 100%;
}
.about-image img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  margin-top: 2rem;
}

.about-me-main {
  position: relative;
  width: 105%;
  margin: 0 auto;
}

.about-me-landing {
  font-family: Poppins;
  position: absolute;
  padding: 50px;
  width: 35%;
  min-width: 350px;
  height: 350px;
  background-color: #f8f3ed;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  margin-left: 90px;
}

.about-me-landing p {
  font-size: 0.9rem;
  margin: 20px 0;
}
.about-me-landing button {
  background-color: transparent;
  padding: 5px 20px;
  transition: all 0.3s ease-in;
  border: 1px solid #333;
}
.about-me-landing button:hover {
  background-color: black;
  color: white;
}
.about-me-div {
  position: absolute;
  background-color: #f8f3ed;
  top: -3%;
  right: 10%;
  transform: skewX(-30deg);
}
.about-me-text {
  padding: 20px 60px;
  transform: skewX(30deg);
  font-family: "Lato";
}
@media (max-width: 500px) {
  .about-me-div {
    display: none;
    /* padding: -20px -30px; */
  }
  .about-me-text {
    font-size: 0.8rem;
  }
  .about-me-landing {
    left: 24.5%;
  }
}
.video-gallery-both {
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
  font-family: Poppins;
  margin-top: 50px;
}
.videography-head-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.videogallery-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  overflow: hidden;
}
.videogallery-images img {
  flex: 1;
  max-width: 100%;
  /* padding: 10px; */
  /* height: 230px; */
  /* width: 400px; */
  /* min-width: 300px; */
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 110% auto; */
  transition: 0.5s;
  object-fit: cover;
  border: 1px solid #333;
}
@media (max-width: 768px) {
  .videogallery-images {
    flex-wrap: wrap;
  }
}
.videography-head-bar p {
  color: rgb(103, 103, 103);
  font-size: 2rem;
}
.see-all-button {
  transition: all 350ms ease;
  background-color: black;
  color: white;
  padding: 5px 20px;
}

.see-all-button:hover {
  background-color: white;
  color: black;
}

@media (max-width: 500px) {
  /* .about-image img {
    height: 70vh;
  } */
  .videography-head-bar p {
    font-size: 18px;
  }
  .see-all-button {
    font-size: 10px;
    /* margin-right: 0.7rem; */
  }
  .video-gallery-both {
    padding: 0 32px;
  }
}
@media (max-width: 374px) {
  .see-all-button {
    font-size: 10px;
    margin-right: 0.1rem;
  }
}
.videogallery-images img:hover {
  transform: scale(1.1);
  filter: brightness(70%);
  border: none;
}

.example1 {
  height: 50px;
  overflow: hidden;
  position: relative;
  margin-top: 50px;
}
.example1 h3 {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: example1 10s linear infinite;
  -webkit-animation: example1 10s linear infinite;
  animation: example1 10s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes example1 {
  0% {
    -moz-transform: translateX(100%);
  }
  40% {
    -moz-transform: translateX(0%);
  }
  60% {
    -moz-transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes example1 {
  0% {
    -webkit-transform: translateX(100%);
  }
  40% {
    -webkit-transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes example1 {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  40% {
    -moz-transform: translateX(0%); /* Firefox bug fix */
    -webkit-transform: translateX(0%); /* Firefox bug fix */
    transform: translateX(0%);
  }
  60% {
    -moz-transform: translateX(0%); /* Firefox bug fix */
    -webkit-transform: translateX(0%); /* Firefox bug fix */
    transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
