.product-image {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: -100;
  object-fit: cover;
  /* object-position: 50% 50%; */
}
h1 {
  -webkit-text-stroke: 1px black;
}
.images-shoot-corporate {
  position: absolute;
  top: 40%;
  left: 33.5%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  .images-shoot-corporate {
    font-size: 2rem;
    left: 30%;
  }
}
@media (max-width: 500px) {
  .images-shoot-corporate {
    font-size: 2rem;
    left: 20%;
  }
}
@media (max-width: 400px) {
  .images-shoot-corporate {
    font-size: 2rem;
    left: 12%;
  }
}
.video-shoot-container {
  column-count: 3;
  margin: auto;
  width: 70%;
}
