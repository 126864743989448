.product-image {
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: -100;
  object-fit: cover;
  /* object-position: 50% 50%; */
}
.images-shoot {
  position: absolute;
  top: 40%;
  left: 35.5%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3.5rem;
}
.video-shoot-container {
  column-count: 3;
  margin: auto;
  width: 70%;
}
